<template>
  <div style="background: #ffffff">
    <el-dialog :show-close="false" width="400px" title="Account 管理系统" :close-on-click-modal="false" :visible="visible" @close="close">
      <div v-if="showForm">
        <h3 style="text-align: center; margin: 0 0 20px 0">
          重置密码
        </h3>
        <el-form ref="form" :model="formModel" :rules="formRule" label-width="0px">
          <el-form-item prop="password">
            <el-input v-model="formModel.password" size="small" type="password" :placeholder="`请输入新的密码(${pwdPlaceholder})`" />
          </el-form-item>
          <el-form-item prop="surePassword">
            <el-input v-model="formModel.surePassword" type="password" size="small" placeholder="确认密码" />
          </el-form-item>
        </el-form>
        <div style="display: flex; justify-content: center; margin-top: 40px">
          <el-button size="small" type="primary" style="width: 120px" @click="submit">
            完成
          </el-button>
        </div>
      </div>
      <p v-else class="pwd-success">
        <i class="el-icon-success pwd-success-icon" />密码重置成功，请重新登录
      </p>
    </el-dialog>
  </div>
</template>

<script>
import { resetPassword } from "@/api/account";
import PwdRule from '@/mixins/pwd-rule';
export default {
  name: 'ResetPassword',
  mixins: [PwdRule],
  data () {
    return {
      visible: true,
      showForm: true,
      formModel: {
        password: '',
        surePassword: ''
      },
      formRule: {
        password: [
          { required: true, message: '密码不能为空' },
          { validator: (...args) => this.checkPassword(...args) }
        ],
        surePassword: [
          { required: true, message: '密码不能为空' },
          { validator: (r, v, cb) => cb(v !== this.formModel.password ? new Error('两次秘密不一致') : undefined) },
        ],
      }
    };
  },
  methods: {
    checkPassword (rule, value, cb) {
      if (/\s/.test(value)) {
        cb(new Error('密码不能包含空格'));
      } else {
        cb();
      }
    },
    close () {
      this.visible = false;
    },
    async submit () {
      try {
        const state = await this.$refs.form.validate();
        if (!state) return;
        const res = await resetPassword({
          password: this.formModel.password,
          confirmPassword: this.formModel.surePassword,
          path: this.$route.params.id,
        });
        this.showForm = false;
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pwd-success {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.el-icon-success:before {
  font-size: 30px;
  color: #50c33c;
}
</style>
